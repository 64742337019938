import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ReactNativeTalk from './pages/ReactNativeTalk';
import WelcomeBackJuan from './pages/WelcomeBackJuan';
import QRCode from './pages/QRCode';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<ReactNativeTalk />}></Route>
        <Route path='react-native-talk' element={<ReactNativeTalk />}></Route>
        <Route path='welcome-back-juan' element={<WelcomeBackJuan />}></Route>
        <Route path='qr' element={<QRCode />}></Route>
        <Route path='*' element={<ReactNativeTalk />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
