import logotype from '../../eddymx-logotype.svg';
import '../../App.css';
import ContactForm from '../../components/ContactForm';
import Footer from '../../components/Footer';
import handleLinkClick from '../../utils/handleLinkClick';

function ReactNativeTalk() {
  return (
    <div 
    className='App'
    >
      <header className='App-header'>
        <img src={logotype} className='App-logo' alt='logo' />
        <h2>Resources</h2>
        <a
          className='App-link'
          href='https://www.figma.com/proto/opHafVTOYHfe4q1hBrP4yb/Mobile-Development?page-id=0%3A1&type=design&node-id=18-671&viewport=-6641%2C592%2C0.25&t=L2r7VzKUEqeX4X7a-1&scaling=contain&starting-point-node-id=18%3A671&mode=design'
          target='_blank'
          rel='noopener noreferrer'
          onClick={handleLinkClick}
        >
          React Native Mobile Development Presentation
        </a>
        <a
          className='App-link'
          href='https://roadmap.sh/react-native?s=65ab09e00c548122830a2eb3'
          target='_blank'
          rel='noopener noreferrer'
          onClick={handleLinkClick}
        >
          Roadmap.sh - React Native
        </a>
        <a
          className='App-link'
          href='https://reactnative.dev/docs/environment-setup#:~:text=Installing%20dependencies'
          target='_blank'
          rel='noopener noreferrer'
          onClick={handleLinkClick}
        >
          React Native - Developer environment setup
        </a>
        <a
          className='App-link'
          href='https://legacy.reactjs.org/docs/hooks-intro.html'
          target='_blank'
          rel='noopener noreferrer'
          onClick={handleLinkClick}
        >
          React JS - useState hook
        </a>
        <a
          className='App-link'
          href='https://reactnavigation.org/docs/getting-started#:~:text=Version%3A%206.x-,Getting%20started,-What%20follows%20within'
          target='_blank'
          rel='noopener noreferrer'
          onClick={handleLinkClick}
        >
          React Native - Navigation
        </a>
        <a
          className='App-link'
          href='https://reactnative.dev/docs/style'
          target='_blank'
          rel='noopener noreferrer'
          onClick={handleLinkClick}
        >
          React Native - The style prop
        </a>
        <a
          className='App-link'
          href='https://www.digitalocean.com/community/tutorials/how-to-add-login-authentication-to-react-applications'
          target='_blank'
          rel='noopener noreferrer'
          onClick={handleLinkClick}
        >
          React JS - Login authentication
        </a>
        <a
          className='App-link'
          href='https://www.npmjs.com/package/react-native-keychain'
          target='_blank'
          rel='noopener noreferrer'
          onClick={handleLinkClick}
        >
          React Native - npm keychain (for touch/face ID)
        </a>
        <a
          className='App-link'
          href='https://play.google.com/intl/es/console/about/'
          target='_blank'
          rel='noopener noreferrer'
          onClick={handleLinkClick}
        >
          App store - Google Play Console
        </a>
        <a
          className='App-link'
          href='https://appstoreconnect.apple.com/'
          target='_blank'
          rel='noopener noreferrer'
          onClick={handleLinkClick}
        >
          App store - App Store connect (apple)
        </a>
        <a
          className='App-link'
          href='https://rnfirebase.io/'
          target='_blank'
          rel='noopener noreferrer'
          onClick={handleLinkClick}
        >
          React Native - Analytics with Firebase
        </a>
        <a
          className='App-link'
          href='https://analytics.google.com/'
          target='_blank'
          rel='noopener noreferrer'
          onClick={handleLinkClick}
        >
          React JS - Google Analytics
        </a>
        <a
          className='App-link'
          href='https://sentry.io/welcome/'
          target='_blank'
          rel='noopener noreferrer'
          onClick={handleLinkClick}
        >
          React Native - App monitoring with Sentry.io
        </a>
        <a
          className='App-link'
          href='https://codepen.io/eddyvarela-sx4/pen/wvyrBJE'
          target='_blank'
          rel='noopener noreferrer'
          onClick={handleLinkClick}
        >
          CodePen - Fix a button
        </a>
      </header>
      <ContactForm />
      <Footer />
    </div>
  );
}

export default ReactNativeTalk;
