function handleGtag(url, type, uploadedImage) {

  // Log the click event to Google Analytics
  window.gtag('event', 'qr-downloaded', {
    event_category: 'QR code download',
    type: type,
    url: url,
    image: uploadedImage ? 'yes' : 'no',
    env: process.env.REACT_APP_ENV,
  });
}

export default handleGtag;
