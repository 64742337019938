function handleLinkClick(event) {
  event.preventDefault();

  const linkHref = event.target.getAttribute('href');
  const linkName = event.target.textContent || event.target.innerText;

  // Log the click event to Google Analytics
  window.gtag('event', 'clicked_link', {
    event_category: 'Link Click',
    event_label: linkName,
    event_value: linkHref,
    env: process.env.REACT_APP_ENV
  });

  // Open the link in a new tab
  window.open(event.target.href, '_blank');
}

export default handleLinkClick;
