import React, { useState, useEffect } from 'react';
import emailjs from 'emailjs-com';
import '../../App.css';
import contact from '../../contact.png';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Swal from 'sweetalert2';
import {
  GoogleReCaptcha,
  GoogleReCaptchaProvider,
} from 'react-google-recaptcha-v3';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
    category: 'programming', // Default category
  });
  const [recaptcha, setRecaptcha] = useState(false);

  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 600); // Change the threshold based on your desired breakpoint
    };

    // Initial check on mount
    handleResize();

    // Event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleChange = (e) => {
    // Handle other input fields
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Replace these values with your Email.js service ID, template ID, and user ID
    const serviceID = 'service_6gpykka';
    const templateID = 'template_994b9up';
    const userID = 'Km8mJGgI7vdOnYzMB';

    if (!recaptcha) {
      console.log('Recaptcha failed');
      return;
    }

    emailjs
      .sendForm(serviceID, templateID, e.target, userID)
      .then((response) => {
        console.log('Email sent successfully:', response);
        setFormData({
          name: '',
          email: '',
          message: '',
          category: 'programming',
        });
        Swal.fire('¡Éxito!', 'Correo enviado correctamente', 'success');
        // Custom gtag event for email sent
        window.gtag('event', 'email_sent', {
          event_category: 'Contact Form',
          event_label: 'Email Sent',
          env: process.env.REACT_APP_ENV
        });
      })
      .catch((error) => {
        console.error('Error sending email:', error);
      });
  };

  return (
    <div
      style={{
        boxSizing: 'border-box',
        backgroundColor: '#282c34',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: isSmallScreen ? '30px 10px' : '50px',
      }}
    >
      <h1 style={{ color: 'white' }}>Contact me</h1>
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: isSmallScreen ? 'column' : 'row', // Change flex direction based on screen size
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div style={{ width: '100%', alignSelf: 'center' }}>
          <img src={contact} className='App-logo' alt='logo' />
        </div>

        <form
          onSubmit={handleSubmit}
          style={{
            width: '100%',

            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <TextField
            select
            label='Category'
            variant='filled'
            id='category'
            name='category'
            required
            // helperText='Please select a category'
            value={formData.category}
            onChange={handleChange}
            style={{
              width: '80%',
              backgroundColor: 'white',
              margin: 10,
            }}
          >
            <MenuItem value='programming-question'>
              I have a programming question
            </MenuItem>
            <MenuItem value='resume'>I want to send my resume</MenuItem>
            <MenuItem value='other'>Other</MenuItem>
          </TextField>
          {/* Conditional text based on the selected category */}
          {formData.category === 'resume' && (
            <p
              style={{
                color: 'white',
                margin: '10px',
                padding: '0 50px',
                fontSize: '12px',
              }}
            >
              If you want to send your resume on PDF, please email
              <a href='mailto: careers@eddy.mx' className='App-link'>
                careers@eddy.mx
              </a>
              but you can always use this form to add a google drive link 😉
            </p>
          )}
          <TextField
            label='Name'
            variant='filled'
            type='text'
            id='name'
            name='name'
            value={formData.name}
            onChange={handleChange}
            required
            style={{
              width: '80%',
              backgroundColor: 'white',
              margin: 10,
            }}
          />

          <TextField
            label='Email'
            variant='filled'
            type='email'
            id='email'
            name='email'
            value={formData.email}
            onChange={handleChange}
            required
            style={{
              width: '80%',
              backgroundColor: 'white',
              margin: 10,
            }}
          />
          <TextField
            label='Message'
            variant='filled'
            id='message'
            name='message'
            value={formData.message}
            onChange={handleChange}
            required
            multiline
            maxRows={4}
            style={{
              width: '80%',
              backgroundColor: 'white',
              margin: 10,
            }}
          />
          <Button
            variant='contained'
            type='submit'
            style={{
              backgroundColor: '#FD1593',
            }}
          >
            Send message
          </Button>
          <GoogleReCaptchaProvider reCaptchaKey='6LeAglgpAAAAAFdyP2fhn1bOLPmOX-FCjBiGhN-k'>
            {/* <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_RECAPTCHA_SITE_KEY}> */}
            <GoogleReCaptcha onVerify={() => setRecaptcha(true)} />
          </GoogleReCaptchaProvider>
        </form>
      </div>
    </div>
  );
};

export default ContactForm;
