import React, { useState, useRef, useEffect } from 'react';
import { QRCodeSVG } from 'qrcode.react';
import image from './image.jpg';
import { Canvg } from 'canvg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import html2canvas from 'html2canvas';
import Footer from '../../components/Footer';
import handleGtag from '../../utils/handleGtag';

function QRCode() {
  const [url, setUrl] = useState('');
  const [uploadedImage, setUploadedImage] = useState(null);
  const [imageSize, setImageSize] = useState(
    window.innerWidth >= 500 ? 500 : window.innerWidth
  );
  const [imageDimensions, setImageDimensions] = useState({
    width: 0,
    height: 0,
    ratio: 0,
  });
  const [typingTimeout, setTypingTimeout] = useState(null);
  const [downloadFormat, setDownloadFormat] = useState('png');
  const [pngImage, setPngImage] = useState(null); // State to store the PNG image
  const [position, setPosition] = useState(0);

  // Function to capture the QR code container and convert it to PNG
  const captureQRCodeToPNG = async () => {
    const qrCodeContainer = document.getElementById('qrContainer');
    const canvas = await html2canvas(qrCodeContainer);

    setPngImage(canvas.toDataURL('image/png'));
  };

  const [scrollOffset, setScrollOffset] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollOffset(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const qrCodeContainer = document.getElementById('qrContainer');
    const rect = qrCodeContainer.getBoundingClientRect();
    setPosition(rect.top + scrollOffset);

    captureQRCodeToPNG();
  }, [url, imageSize, uploadedImage, scrollOffset]);

  const [screenWidth] = useState(
    window.innerWidth >= 500 ? 500 : window.innerWidth
  );
  const canvasRef = useRef(null);
  const qrCodeRef = useRef(null);

  useEffect(() => {
    // Cleanup the typingTimeout when component unmounts
    return () => {
      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }
    };
  }, [typingTimeout]);

  const handleRemoveImage = () => {
    // Clear the selected file when removing the image
    setUploadedImage(null);
    window.gtag('event', 'qr-remove-logo', {
      event_category: 'QR remove logo',
      url: url,
      image: 'no',
      env: process.env.REACT_APP_ENV,
    });
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target.result;
        img.onload = () => {
          // Update the state with the image dimensions
          setImageDimensions({
            width: img.width,
            height: img.height,
            ratio: img.height / img.width,
          });
          setUploadedImage(event.target.result);
          const qrCodeContainer = document.getElementById('qrContainer');
          const rect = qrCodeContainer.getBoundingClientRect();
          setPosition(rect.top + scrollOffset);
        };
      };
      reader.readAsDataURL(file);
    }

    window.gtag('event', 'add-logo', {
      event_category: 'Add logo to QR',
      url: url,
      image: 'yes',
      env: process.env.REACT_APP_ENV,
    });
  };

  const handleDownload = () => {
    if (!canvasRef.current) return;

    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');

    // Set the canvas size
    canvas.width = screenWidth;
    canvas.height = screenWidth;

    const qrCodeSVG = document.querySelector('#qrContainer svg');
    const svgString = new XMLSerializer().serializeToString(qrCodeSVG);

    // Create a Canvg instance to render the QR code SVG onto the canvas
    const canvgInstance = Canvg.fromString(context, svgString, {
      ignoreMouse: true,
      ignoreAnimation: true,
      imageCache: false,
    });

    canvgInstance.render().then(() => {
      const img = new Image();
      img.src = image;
      img.onload = () => {
        // Draw the image on the canvas

        // Convert the canvas to a PNG image
        const pngUrl = canvas.toDataURL('image/png');

        // Create a download link
        const a = document.createElement('a');
        a.href = pngUrl;
        a.download = 'qr-image.png';

        // Trigger the download
        a.click();
      };
    });
    handleGtag(url, 'png', uploadedImage);
  };

  const handleDownloadSVG = () => {
    if (!qrCodeRef.current) return;

    const qrCodeSVG = qrCodeRef.current.querySelector('svg');
    const svgString = new XMLSerializer().serializeToString(qrCodeSVG);

    const blob = new Blob([svgString], { type: 'image/svg+xml' });
    const urlObject = URL.createObjectURL(blob);

    // Create a download link
    const a = document.createElement('a');
    a.href = urlObject;
    a.download = 'qr-code.svg';

    // Trigger the download
    a.click();

    // Clean up
    URL.revokeObjectURL(urlObject);
    handleGtag(url, 'svg', uploadedImage);
  };

  const handleSliderChange = (e) => {
    setImageSize(Number(e.target.value));
    window.gtag('event', 'qr-logo-slider', {
      event_category: 'QR logo size change',
      url: url,
      env: process.env.REACT_APP_ENV,
    });
  };

  const handleInputChange = (e) => {
    // Clear the existing timeout
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    // Set a new timeout
    const newTimeout = setTimeout(() => {
      // Trigger gtag event after 5 seconds of continuous typing
      window.gtag('event', 'qr-text', {
        event_category: 'QR code typing',
        url: e.target.value,
        env: process.env.REACT_APP_ENV,
      });
    }, 5000);

    setTypingTimeout(newTimeout);

    // Update the URL state
    setUrl(e.target.value);
  };

  const handleFormatChange = (e) => {
    setDownloadFormat(e.target.value);
    window.gtag('event', 'qr-download-type', {
      event_category: 'QR select download type',
      url: url,
      type: e.target.value,
      env: process.env.REACT_APP_ENV,
    });
  };

  const handleDownloadButton = () => {
    if (downloadFormat === 'png') {
      handleDownload();
    } else if (downloadFormat === 'svg') {
      handleDownloadSVG();
    }
  };

  return (
    <div
      style={{
        width: '100vw',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#292C33',
      }}
    >
      <p
        style={{
          color: '#E93B91',
          fontSize: 24,
          fontWeight: 'bold',
        }}
      >
        QR Code Generator
      </p>
      <p
        style={{
          color: '#E93B91',
          fontSize: 20,
          margin: '0 10px 24px',
          textAlign: 'center',
        }}
      >
        Create QR codes, add logos and download them
      </p>
      <div style={{ minWidth: 300, display: 'flex', flexDirection: 'column' }}>
        <label
          style={{
            color: 'white',
            margin: '5px 10px 5px',
            textAlign: 'center',
          }}
        >
          QR content:
        </label>
        <input
          type='text'
          value={url}
          onChange={handleInputChange}
          placeholder='Write something...'
          style={{
            backgroundColor: '#585E6C',
            border: 'none',
            padding: '7px 20px',
            textAlign: 'center',
            fontSize: '16px',
            borderRadius: 5,
            margin: '0 5px 5px',
            WebkitTextFillColor: 'white', // For some Webkit browsers
            '::placeholder': { color: 'white' },
            MozPlaceholder: { color: 'white' }, // For some older Firefox browsers
          }}
        ></input>
        <label
          htmlFor='fileInput'
          style={{
            backgroundColor: '#E93B91',
            color: 'white',
            padding: '7px 50px',
            borderRadius: '5px',
            margin: 5,
            cursor: 'pointer',
            textAlign: 'center',
          }}
        >
          Add a logo
        </label>
        <input
          id='fileInput'
          type='file'
          accept='image/*'
          onChange={handleImageUpload}
          style={{ display: 'none' }}
        />
        {uploadedImage && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <button
              onClick={handleRemoveImage}
              style={{
                margin: '5px 5px 0',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#585E6C',
                color: 'white',
                padding: '5px 10px',
                borderRadius: '20px',
                border: 'none',
                cursor: 'pointer',
              }}
            >
              Remove Image
              <FontAwesomeIcon
                icon={faXmark}
                style={{
                  color: 'white',
                  fontSize: '1em',
                  margin: '0 0 0 10px',
                }}
              />
            </button>
            <div
              style={{
                margin: '10px 0',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <label
                style={{ color: 'white', textAlign: 'center', width: '100%' }}
              >
                Change logo size
              </label>

              <input
                id='imageSizeSlider'
                type='range'
                min='50'
                max={screenWidth * 2}
                value={imageSize}
                onChange={handleSliderChange}
                style={{
                  width: '100%',
                  cursor: 'pointer',
                  marginTop: '10px',
                  appearance: 'none',
                  borderRadius: '50px',
                  background:
                    'linear-gradient(90deg, #585E6C, #585E6C ' +
                    (imageSize / (screenWidth * 3)) * 100 +
                    '%, #585E6C ' +
                    (imageSize / (screenWidth * 2)) * 100 +
                    '%)',
                }}
              />

              {imageSize >= screenWidth * 2 * 0.8 ? (
                <label
                  style={{
                    color: '#E93B91',
                    fontSize: 12,
                    maxWidth: 250,
                    textAlign: 'center',
                  }}
                >
                  Warning: if the size of the image is too big, the QR might not
                  work
                </label>
              ) : null}
              <style>
                {`
            input[type="range"]::-webkit-slider-thumb {
              -webkit-appearance: none;
              appearance: none;
              width: 20px;
              height: 20px;
              border-radius: 50%;
              background-color: #E93B91;
              cursor: pointer;
            }

            input[type="range"]::-moz-range-thumb {
              width: 20px;
              height: 20px;
              border-radius: 50%;
              background-color: #E93B91;
              cursor: pointer;
            }
          `}
              </style>
            </div>
          </div>
        )}
      </div>
      <div
        id='qrContainer'
        ref={qrCodeRef}
        style={{
          margin: '10px 0',
          // pointerEvents: 'visible'
        }}
      >
        <QRCodeSVG
          value={url}
          renderAs='svg'
          size={screenWidth}
          bgColor='white'
          fgColor='black'
          level='H'
          includeMargin={true}
          imageSettings={{
            src: uploadedImage || '',
            width: imageSize / 5,
            height: (imageSize / 5) * imageDimensions.ratio,
            excavate: false,
          }}
        />
      </div>
      <div
        style={{
          minWidth: 300,
          // width: 300,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <label
          style={{
            color: 'white',
            maxWidth: 250,
            textAlign: 'center',
            boxSizing: 'border-box',
            padding: '10px 0',
          }}
        >
          Choose the format of download for your QR
        </label>
        <select
          value={downloadFormat}
          onChange={handleFormatChange}
          style={{
            backgroundColor: 'white',
            border: 'null',
            borderRadius: 5,
            margin: 5,
            padding: '5px 10px',
            width: '100%',
            fontSize: '16px',
          }}
        >
          <option value='png'>PNG</option>
          <option value='svg'>SVG</option>
        </select>
        <button
          onClick={handleDownloadButton}
          style={{
            backgroundColor: '#6A3BAB',
            color: 'white',
            padding: '7px 50px',
            borderRadius: '5px',
            margin: '5px 0 35px',
            border: 'none',
            cursor: 'pointer',
            fontSize: '16px',
            width: '100%',
          }}
        >
          Download QR code
        </button>
      </div>
      <canvas ref={canvasRef} style={{ display: 'none' }}></canvas>
      {/* render png version of the code here */}
      {pngImage && (
        <div>
          <img
            src={pngImage}
            alt='QR code'
            style={{
              // display: 'none',
              width: screenWidth,
              // pointerEvents: 'none',
              position: 'absolute',
              top: position,
              marginLeft: 'auto',
              marginRight: 'auto',
              left: 0,
              right: 0,
            }}
          />
        </div>
      )}
      <Footer />
    </div>
  );
}

export default QRCode;
