import '../../App.css';
import Footer from '../../components/Footer';
import bienvenido from '../../bienvenido.gif'

function WelcomeBackJuan() {
  return (
    <div className='App'>
      <header className='App-header'>
        <img src={bienvenido} className='App-logo' alt='logo' />
        <h2>Juan</h2>
       
      </header>
      <Footer />
    </div>
  );
}

export default WelcomeBackJuan;
