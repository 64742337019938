import React, { useEffect, useState } from 'react';
import logo from '../../eddymx-logotype-white.svg'; // Replace with the path to your logo image
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faInstagram,
  faLinkedin,
  faGithub,
} from '@fortawesome/free-brands-svg-icons';
import handleLinkClick from '../../utils/handleLinkClick';

const Footer = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 600); // Change the threshold based on your desired breakpoint
    };

    // Initial check on mount
    handleResize();

    // Event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div
      style={{
        backgroundColor: '#6A3BAB',
        padding: isSmallScreen ? '20px' : '20px 70px',
        display: 'flex',
        justifyContent: isSmallScreen ? 'center' : 'space-between',
        flexDirection: isSmallScreen ? 'column' : 'row', // Change flex direction based on screen size
        alignItems: 'center',
        color: 'white',
        width: '100%',
        boxSizing: 'border-box',
      }}
    >
      <div>
        <a
          href='/'
          style={{ textDecoration: 'none' }}
          onClick={() => {
            window.gtag('event', 'clicked_link', {
              event_category: 'Link Click',
              event_label: 'Footer logo',
              event_value: '/',
              env: process.env.REACT_APP_ENV
            });
          }}
        >
          <img
            src={logo}
            alt='Your Logo'
            style={{ height: '50px', padding: '20px' }}
          />
        </a>
      </div>
      <div>
        <a
          href='https://www.instagram.com/eddyvarela97/'
          target='_blank'
          rel='noopener noreferrer'
          onClick={handleLinkClick}
        >
          <FontAwesomeIcon
            icon={faInstagram}
            style={{ color: 'white', fontSize: '2em', margin: '0 10px' }}
          />
        </a>
        <a
          href='https://www.linkedin.com/in/eddyvarelae/'
          target='_blank'
          rel='noopener noreferrer'
          onClick={handleLinkClick}
        >
          <FontAwesomeIcon
            icon={faLinkedin}
            style={{ color: 'white', fontSize: '2em', margin: '0 10px' }}
          />
        </a>
        <a
          href='https://github.com/eddyvarela97'
          target='_blank'
          rel='noopener noreferrer'
          onClick={handleLinkClick}
        >
          <FontAwesomeIcon
            icon={faGithub}
            style={{ color: 'white', fontSize: '2em', margin: '0 10px' }}
          />
        </a>
      </div>
    </div>
  );
};

export default Footer;
